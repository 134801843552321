import React, { ReactElement, Fragment, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles2';
import { breakpoints } from '../../styles/variables';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import "./styles2.css"
import { useLocation } from '@reach/router';

export const OptionTwo = ( ): ReactElement => {
 
  const optionTwoRef = useRef(null);
  const { isVisible } = useVisibilityDetection(optionTwoRef);
  const location = useLocation(); 
  
  return (

    <Styled.SafariWrapper >
      <Styled.Wrapper ref={optionTwoRef} isVisible={isVisible}>
        <Styled.OptionBanner><img src="/salespage/8th section/option-2.svg"/></Styled.OptionBanner>

        <Styled.Section>

          <div className='left'>
        <TwoColSection
          styles={css`
            
            max-width: 1170px;
            margin-top: 30px;

            /* opacity: 0;
            transform: translateY(10%);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            ${isVisible &&
              `
                opacity: 1;
                transform: translateY(0);
              `}*/

            @media screen and (max-width: ${breakpoints.lg}px) {
              margin-top: 70px;
            }
            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 0px;
              padding:0;
            }
            

            
          `}

          leftColumn={
            <Styled.LeftColumn css={css`
                                      width: 450px;
                                      margin-left: 50px;
                                      height:200px;
                                      @media screen and (max-width: ${breakpoints.xl}px) {
                                        margin-left: 0px;
                                      }
                                      @media screen and (max-width: ${breakpoints.lg}px) {
                                        margin-bottom:30px;
                                        height:inherit;
                                      }
                                      @media screen and (max-width: ${breakpoints.sm}px) {
                                        width: 100%;
                                      }
                                      
                                      `}> 
                <Styled.SubTitle>Use a preset or custom size</Styled.SubTitle>
                <Styled.MiniSub>What's your use case? Instagram post, presentation or visual notes? Select from 19 handy preset or custom size you prefer.</Styled.MiniSub>
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`width: 550px; margin-left: 60px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              margin-left: 0px;width:450px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              width: 100%;
            }
            `}>
              <div  className='arrowDown'>
                <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:80%;
                    }
                    @media screen and (max-width: ${breakpoints.lg}px) {
                      width:90%;
                    }
                    @media screen and (max-width: ${breakpoints.sm}px) {
                      width:100%;
                    }
                    
                    `}
                    alt="Illustration"
                    src="/salespage/8th section/preset-size.png"
                    loading="lazy"
                    
                  />
                </div>
            </Styled.RightColumn>
          }
        />
        </div>
       <br/>


      <TwoColSection
          styles={css`
            
            max-width: 1170px;
            margin-top: 40px;

            /*opacity: 0;
            transform: translateY(10%);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            ${isVisible &&
              `
                opacity: 1;
                transform: translateY(0);
              `}*/

            @media screen and (max-width: ${breakpoints.lg}px) {
              padding:0;
              margin-top:20px;
            }

            
          `}



          leftColumn={
            <Styled.LeftColumn css={css`width: 550px;
            @media screen and (max-width: ${breakpoints.xl}px) {
             width:450px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              width: 100%;
            }
            `}> 
              <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:80%;
                    }
                    @media screen and (max-width: ${breakpoints.lg}px) {
                      width:90%;
                    }
                    @media screen and (max-width: ${breakpoints.sm}px) {
                      width:100%;
                    }
                    `}              
                  alt="Illustration"
                  src="/salespage/8th section/hundreds-of-icons-2s.gif"
                  loading="lazy"
                />
                
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`
            width: 450px;
            margin-left: 50px;
            height:200px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              margin-left: -30px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              margin-bottom:30px;
              margin-left:0;
              height:inherit;
            }
            @media screen and (max-width: ${breakpoints.sm}px) {
              width: 100%;
            }
            `}>
                <Styled.SubTitle>Select from hundreds of icons</Styled.SubTitle>
                <Styled.MiniSub>Search & browse hundreds of elements (objects, arrows, frames, speech bubbles and more). Drag-and-drop them right onto the canvas.</Styled.MiniSub>
              
            </Styled.RightColumn>
          }
        />
        <br/>
        
        <div className='left'>
        <TwoColSection
          styles={css`
            
            max-width: 1170px;
            margin-top: 30px;

            /* opacity: 0;
            transform: translateY(10%);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            ${isVisible &&
              `
                opacity: 1;
                transform: translateY(0);
              `} */

            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 20px;
              padding:0;
            }

            
          `}



          leftColumn={
            <Styled.LeftColumn css={css`
                                      width: 450px;
                                      margin-left: 50px;
                                      height:200px;
                                      @media screen and (max-width: ${breakpoints.xl}px) {
                                        margin-left: 0px;
                                      }
                                      @media screen and (max-width: ${breakpoints.lg}px) {
                                        margin-bottom:30px;
                                        height:inherit;
                                      }
                                      @media screen and (max-width: ${breakpoints.sm}px) {
                                        width: 100%;
                                      }
                                      `}> 
                <Styled.SubTitle>Autodraw = Doodler's dream</Styled.SubTitle>
                <Styled.MiniSub>Don't worry about being perfect. SketchWow fixes your messy mouse freehand. Hold down your shift key... watch the magic happen </Styled.MiniSub>
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`width: 550px; margin-left: 60px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              margin-left: 0px;width:450px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              width: 100%;
            }
            `}>
              
              <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:80%;
                    }
                    @media screen and (max-width: ${breakpoints.lg}px) {
                      width:90%;
                    }
                    @media screen and (max-width: ${breakpoints.sm}px) {
                      width:100%;
                    }
                    `}
                  alt="Illustration"
                  src="/salespage/8th section/auto-draw.gif"
                  loading="lazy"
                />
            </Styled.RightColumn>
          }
        />
        </div>
        <br/>

        <TwoColSection
          styles={css`
            
            max-width: 1170px;
            margin-top: 40px;

            /*opacity: 0;
            transform: translateY(10%);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            ${isVisible &&
              `
                opacity: 1;
                transform: translateY(0);
              `}*/

            @media screen and (max-width: ${breakpoints.xl}px) {
              padding:0;
            }
            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 20px;
              padding:0;
            }

            
          `}



          leftColumn={
            <Styled.LeftColumn css={css`width: 550px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              width:450px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              width: 100%;
            }
            `}> 
              <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:80%;
                    }
                    @media screen and (max-width: ${breakpoints.lg}px) {
                      width:90%;
                    }
                    @media screen and (max-width: ${breakpoints.sm}px) {
                      width:100%;
                    }
                    `}
                  alt="Illustration"
                  src="/salespage/8th section/add-animation.gif"
                  loading="lazy"
                />
                
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`
            width: 450px;
            margin-left: 50px;
            height:200px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              margin-left: -30px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              margin-bottom:30px;
              margin-left:0;
              height:inherit;
            }
            @media screen and (max-width: ${breakpoints.sm}px) {
              width: 100%;
            }
            `}>
                <Styled.SubTitle>Add eye-catching animation!</Styled.SubTitle>
                <Styled.MiniSub>Nothing adds extra zing to your designs like motion. Choose animated icons or add animated lines to really stand out</Styled.MiniSub>
              
            </Styled.RightColumn>
          }
        />
        <br/>

        <div className='left'>
        <TwoColSection
          styles={css`
            
            max-width: 1170px;
            margin-top: 30px;

            /*opacity: 0;
            transform: translateY(10%);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            ${isVisible &&
              `
                opacity: 1;
                transform: translateY(0);
              `}*/

            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 20px;
              padding:0;
            }

            
          `}



          leftColumn={
            <Styled.LeftColumn css={css`
                                      width: 450px;
                                      margin-left: 50px;
                                      height:200px;
                                      @media screen and (max-width: ${breakpoints.xl}px) {
                                        margin-left: 0px;
                                      }
                                      @media screen and (max-width: ${breakpoints.lg}px) {
                                        margin-bottom:30px;
                                        height:inherit;
                                      }
                                      @media screen and (max-width: ${breakpoints.sm}px) {
                                        width: 100%;
                                      }
                                      `}> 
                <Styled.SubTitle>Keep it fun with characters</Styled.SubTitle>
                <Styled.MiniSub>Use stick people & faces to add fun and emotion to your designs. Makes explaining dull topics waaay more interesting too!</Styled.MiniSub>
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`width: 550px; margin-left: 60px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              margin-left: 0px;width:450px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              width: 100%;
            }
            `}>
              
              <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:80%;
                    }
                    @media screen and (max-width: ${breakpoints.lg}px) {
                      width:90%;
                    }
                    @media screen and (max-width: ${breakpoints.sm}px) {
                      width:100%;
                    }
                    `}
                  alt="Illustration"
                  src= "/salespage/8th section/preset-custom-size.png"
                  loading="lazy"
                />
            </Styled.RightColumn>
          }
        />
        </div>
       <br/>

       <TwoColSection
          styles={css`
            
            max-width: 1170px;
            margin-top: 40px;

            /*opacity: 0;
            transform: translateY(10%);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            ${isVisible &&
              `
                opacity: 1;
                transform: translateY(0);
              `}*/

            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 20px;
              padding:0;
            }

            
          `}



          leftColumn={
            <Styled.LeftColumn css={css`width: 550px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              width:450px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              width: 100%;
            }
            `}> 
              <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:80%;
                    }
                    @media screen and (max-width: ${breakpoints.lg}px) {
                      width:90%;
                    }
                    @media screen and (max-width: ${breakpoints.sm}px) {
                      width:100%;
                    }
                    `}
                  alt="Illustration"
                  src= "/salespage/8th section/customize-fonts-colors.png"
                  loading="lazy"
                />
                
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`
            width: 450px;
            margin-left: 50px;
            height:200px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              margin-left: -30px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              margin-bottom:50px;
              margin-left:0;
              height:inherit;
            }
            @media screen and (max-width: ${breakpoints.sm}px) {
              width: 100%;
            }
            `}>
                <Styled.SubTitle>Customize with fonts and color</Styled.SubTitle>
                <Styled.MiniSub>Choose from over 30 fonts or add your own (in your language). Use our handy color picker to match your favorite or brand colors.</Styled.MiniSub>
              
            </Styled.RightColumn>
          }
        />
        <br/>

        <div className='left'>
        <TwoColSection
          styles={css`
            
            max-width: 1170px;
            margin-top: 30px;

            /*opacity: 0;
            transform: translateY(10%);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            ${isVisible &&
              `
                opacity: 1;
                transform: translateY(0);
              `}*/

            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 20px;
              padding:0;
            }

            
          `}



          leftColumn={
            <Styled.LeftColumn css={css`
                                      width: 450px;
                                      margin-left: 50px;
                                      height:200px;
                                      @media screen and (max-width: ${breakpoints.xl}px) {
                                        margin-left: 0px;
                                      }
                                      @media screen and (max-width: ${breakpoints.lg}px) {
                                        margin-bottom:30px;
                                        height:inherit;
                                      }
                                      @media screen and (max-width: ${breakpoints.sm}px) {
                                        width: 100%;
                                      }
                                      `}> 
                <Styled.SubTitle>Import images, photos, icons...</Styled.SubTitle>
                <Styled.MiniSub>Markup a photo, add images or a logo to your design. 2 clicks is all it takes.</Styled.MiniSub>
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`width: 550px; margin-left: 60px;
            @media screen and (max-width: ${breakpoints.xl}px) {
              margin-left: 0px;width:450px;
            }
            @media screen and (max-width: ${breakpoints.lg}px) {
              width: 100%;
            }
            `}>
              
              <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:80%;
                    }
                    @media screen and (max-width: ${breakpoints.lg}px) {
                      width:90%;
                    }
                    @media screen and (max-width: ${breakpoints.sm}px) {
                      width:100%;
                    }
                    `}
                  alt="Illustration"
                  src=  "/salespage/8th section/import-images.png"
                  loading="lazy"
                />
            </Styled.RightColumn>
          }
        />
        </div>
       <br/>

        </Styled.Section>
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
